<template>
    <div class="flex flex-item flex-column">
        <ts-table-operate :name="nameKey" :table-name="tableName" v-if="cols.length > 0" :cols="cols"
            @handleFilter="handleFilter" @searchShowLeft="searchShowLeft" @handleSort="sort"
            @viewChange="viewChange"></ts-table-operate>
        <ts-table ref="table" class="flex-item" :cols="cols" :data="list" :page-param="pageParam" :loading="loading"
            @handleSort="sort" :error="pageParam.error" :error-code="error" :name="nameKey"
            @handleRowDbClickChange="handleRowDbClickChange" full @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange" :detailApiId="detailApiId">
            <template #searchLeft>
                <ts-search-left v-if="isSearchLeft" :searchCols="searchCols" :table-name="tableName" :cols="cols"
                    @searchClose="searchClose" @handleFilter="handleFilter" :isListOutQueryView="false"></ts-search-left>
            </template>
            <template v-slot:slot_sale="{ props }">
                <el-button class="detail-button" type="primary" round @click="goDrugTotal(props.row)">查看</el-button>
            </template>
        </ts-table>
    </div>
</template>

<script>
import myMixin from '@/util/mixin'
import searchMixin from './searchMixin'
export default {
    mixins: [myMixin, searchMixin],
    props: {
        baseQuery: {
            default: () => ({})
        },
        visitId: {},
    },
    data() {
        return {
            nameKey: 'drugInstruction',
            tableName: 'du_instruction_books',
            pageApi: 'indexDrug/duInstructionBooks/instructionBook',
            detailApiId: {
                api: 'indexDrug/duInstructionBooks/instructionBookShow',
                id: 'duInstructionBookId'
            },


            config: {
                key: 'du_instruction_books'
            },
            cols: [],
        }
    },
    methods: {
        listComputed(list) {
            return list.map(item => {
                if (item.componentLink) {
                    const arr = JSON.parse(item.componentLink)
                    arr.sort((orr1, orr2) => {
                        return orr2.name.length - orr1.name.length
                    })
                    arr.forEach((j, i) => {
                        item.component = item.component.replace(j.name, `##{${i}}##`)
                    })
                    item.component = this.setObj(item.component.split('##'), arr)
                }
                if (item.indicationLink) {
                    const arr = JSON.parse(item.indicationLink)
                    arr.sort((orr1, orr2) => {
                        return orr2.name.length - orr1.name.length
                    })
                    arr.forEach((j, i) => {
                        item.indication = item.indication.replace(j.commonName || j.name, `##{${i}}##`)
                    })
                    item.indication = this.setObj(item.indication.split('##'), arr)
                }
                return item
            })
        },
        setObj(obj, list) {
            return obj.map(orr => {
                if (orr.indexOf("{") != -1) {
                    const t = orr.replace("{", "").replace("}", "")
                    orr = list[t]
                }
                return orr
            })
        },
    }
}
</script>